<template>
  <vs-card type="3" class="eventCard" ref="eventCard">
    <template #title style="width:90%; background: black">
      <h2>{{ title }}</h2>
    </template>
    <template #img style="min-width: 300px;">
      <img :src="img || 'https://picsum.photos/400/400/?image=20'" alt="Image" class="imgEvent" />
    </template>
    <div></div>
    <template #text>
      <div style="text-align:start">
        <b-card-text>{{ description }}</b-card-text>
        <b-card-text>{{ `Distancia: 8km - ${city}` }}</b-card-text>
        <b-card-text>{{ startDate.toLocaleString() }}</b-card-text>
        <b-card-text>{{ ` ${price > 0 ? "Precio :" + price + "€" : "Gratis!"}` }}</b-card-text>
      </div>

      <!-- <Creator :creator="createdBy" :profileImg="this.profileImg" /> -->
    </template>
    <template #buttons>
      <b-card-text v-if="aforo > 0">
        {{ `Quedan solo ${aforo - assistants.length} plazas ` }}
        <i class="fas fa-exclamation"></i>
      </b-card-text>
      <b-card-text>
        <vs-tooltip shadow interactivity>
          {{ "Confirmados: ".concat(assistants.length) }}
          <i class="fas fa-users"></i>
          <template #tooltip>
            <Participants
              :img="img || require('@/assets/Avatar-Sizes-Custom-1.jpg')"
              v-bind:assistants="assistants"
            ></Participants>
          </template>
        </vs-tooltip>
      </b-card-text>
      <vs-button
        v-if="!joined && (aforo ? aforo - assistants.length > 0 : true)"
        :loading="loading.buttons.join"
        @click="onJoin"
        circle
        block
        color="warn"
        gradient
      >
        Join !
      </vs-button>
      <vs-button
        v-if="joined"
        :loading="loading.buttons.join"
        @click="onDisJoin"
        circle
        block
        color="warn"
        gradient
      >
        Disjoin
      </vs-button>
      <vs-button
        v-if="aforo && aforo > 0 ? aforo - assistants.length <= 0 : false"
        circle
        block
        color="danger"
        gradient
      >
        Completo!!
      </vs-button>
    </template>
    <template #interactions>
      <Creator :creator="createdBy" :profileImg="profileImg || img" />
      <vs-button @click="shareEvent" circle icon title="Comparte el evento" floating>
        <i class="fas fa-share-alt"></i>
      </vs-button>

      <vs-button v-if="joined" color="whatsapp" circle icon title="Charlamos?" floating>
        <i class="fas fa-comment-dots"></i>
      </vs-button>
      <vs-button color="#ff3e4e" circle icon title="Me lo guardo pa luego!" floating>
        <i class="fa fa-cart-plus"></i>
      </vs-button>

      <vs-button color="warn" gradient circle icon title="Detalles del evento" floating>
        <i class="fas fa-info-circle"></i>
      </vs-button>
    </template>
  </vs-card>
</template>

<script>
import { imageMix, noficationsDialog } from "@/common/mixin.js";
import Creator from "@/components/Creator.vue";
import Participants from "@/components/ParticipantsAvatars.vue";
import LoginBtn from "@/components/LoginBtn.vue";
import axios from "axios";

export default {
  name: "MiniCard2",
  mixins: [imageMix, noficationsDialog],
  components: {
    Creator,
    Participants
  },
  props: [
    "title",
    "description",
    "img",
    "price",
    "icon",
    "id",
    "city",
    "createdBy",
    "participant",
    "startDate",
    "endDate",
    "aforo"
  ],
  data() {
    return {
      assistants: new Array(Math.floor(Math.random() * 16) + 1).fill(
        Math.floor(Math.random() * 160) + 1
      ),
      loading: {
        buttons: {
          wish: false,
          join: false
        }
      },
      profileImg: null,
      joined: this.$props.participant,
      img2:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQuJCEfooUBWITHKYc7CrcrIs61S6OP_BFiFkWmCwWrx3aiV9v1&usqp=CAU"
    };
  },
  methods: {
    onRedirectPage() {
      this.$emit("onRedirectPage", this.$el.children[0].id);
    },
    async onDisJoin(event) {
      event.stopPropagation();
      this.loading.buttons.join = true;
      const body = {
        username: localStorage.user,
        event_id: this.id
      };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .post("/participation", body)
        .then(response => {
          this.joined = false;
          const deletedInd = this.assistants.findIndex(
            assistant => assistant.id === this.$store.state.currentUser.id
          );
          this.assistants.splice(deletedInd, 1);
        })
        .catch(error => {
          console.log(error);
        });
      this.loading.buttons.join = false;
    },
    async onJoin(event) {
      event.stopPropagation();
      this.loading.buttons.join = true;
      if (!localStorage.user) {
        this.notificateLoginRequired();
      } else {
        const body = {
          username: localStorage.user,
          event_id: this.id
        };
        const apiClient = axios.create({
          baseURL: !window.location.host.includes("localhost")
            ? "https://api.bmyplan.com"
            : "http://localhost:8087" //
        });
        await apiClient
          .post("/participation", body)
          .then(response => {
            this.joined = true;
            this.assistants.push(this.$store.state.currentUser);
          })
          .catch(error => {
            console.log(error);
          });
      }
      this.loading.buttons.join = false;
    },

    shareEvent() {
      if (navigator.share) {
        navigator
          .share({
            title: "B YOUR PLAN :: EVENTS",
            url: window.location.href
          })
          .then(() => {
            console.log("Thanks for sharing!");
          });
      }
    },

    async getProfileData() {
      const body = { params: { username: this.$props.createdBy } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/profile", body)
        .then(response => {
          this.parseImg(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getAssistants() {
      const body = { params: { event_id: this.id } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/participation", body)
        .then(response => {
          this.parseImg(response.data, true);
          this.assistants = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    parseImg(aEntity) {
      aEntity.forEach(entity => {
        if (entity.ProfileImg) {
          const paso1 = new Uint8Array(entity.ProfileImg.data);
          const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
          entity.ProfileImg = paso2;
          this.profileImg = paso2;
        }
      });
    }
  },
  mounted() {
    this.getProfileData();
    this.getAssistants();
    const { style, children } = this.$refs.eventCard.$el.children[0];
    style.color = "white";
    style.background = "#203b40";
    style.width = "100%";
    style.maxWidth = "none";
    style.justifyContent = "space-between";
    style.display = "block";
    children[2].style.padding = "1rem";
    children[1].style.width = "80%";
    children[0].style.minWidth = "250px";
    children[0].style.maxWidth = "250px";
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .eventCard {
    margin: 1rem 0 1rem 0 !important;
    width: min-content !important;
  }
}

.eventCard {
  margin: 1rem;
  width: 100%;
}

.imgEvent {
  max-height: 200px;
}
</style>
