<template>
  <div class="cardGrid light" id="cardG">
    <vs-card type="3" @click.stop.prevent="goDetail" :id="card.id">
      <template #title>
        <h3>{{ card.title }}</h3>
      </template>
      <template #img>
        <img height="200px" :src="card.image || 'https://picsum.photos/400/400/?image=20'" alt="" />
      </template>
      <template #text>
        <div v-if="showDetails" @mouseover="showDetails = !showDetails">
          <p v-if="card.description">
            {{ card.description }}
          </p>
          <p v-else>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </p>
        </div>

        <div class="detailCard" v-else @mouseover="showDetails = !showDetails">
          <div>
            <small> <i class="fas fa-map-marker-alt" />A 1 <b> Km </b> de {{ card.city }} </small>
          </div>
          <div>
            <small><i class="fas fa-calendar-alt"></i>{{ card.startDate }}</small>
          </div>
          <div>
            <small><i class="fas fa-clock"></i> 08:00 - 16:00</small>
          </div>
          <div v-if="card.aforo > 0">
            <small><i class="fas fa-users"></i> Aforo : {{ card.aforo }}</small>
          </div>
          <div v-if="card.price > 0">
            <small><i class="fas fa-ticket-alt"></i> {{ card.price }} €</small>
          </div>
        </div>
        <div class="footerCard">
          <Creator v-if="creator" :creator="creator" :currentUser="currentUser" :mini="true" />
          <!-- @click.prevent.stop="voidFunction" -->
          <div>
            <vs-button
              v-if="!isJoined"
              color="#057f92"
              style="color:white; padding:0;"
              size="small"
              @click.stop="onJoin(card.id, card.assistants)"
            >
              Unirse
            </vs-button>
            <vs-button
              v-else
              color="#fff"
              style="padding:0;"
              size="small"
              @click.stop="onDisJoin(card.id, card.assistants)"
            >
              Retirarse
            </vs-button>
          </div>
        </div>
      </template>
      <template #interactions id="test">
        <!-- <div class="cardLabel">Nuevo</div>
        <div class="cardInteractions">
          <vs-button danger icon floating>
            <i class="fas fa-filter"></i>
          </vs-button>

          <vs-button class="btn-chat" shadow primary>
            <i class="fas fa-filter"></i>
            <span class="span">
              54
            </span>
          </vs-button>
        </div> -->
      </template>
    </vs-card>
  </div>
</template>

<script>
import Creator from "@/components/Creator.vue";
import { loadingMix, noficationsDialog, authMix, eventMix, followMix } from "@/common/mixin.js";

export default {
  name: "Card2",
  props: ["card"],
  mixins: [loadingMix, noficationsDialog, authMix, eventMix, followMix],
  components: {
    Creator
  },
  data() {
    return {
      currentUser: null,
      creator: null,
      showDetails: false,
      isJoined: false
    };
  },
  methods: {
    voidFunction() {
      // console.log(new Date());
    },

    goDetail() {
      this.$store.commit("setEvent", {
        ...this.$props,
        assistants: this.card.assistants,
        private: false
      });
      this.$router.push({ name: "Event" });
    },

    async getCreatorData() {
      const { created_by, assistants } = this.card;
      if (created_by) {
        this.creator = await this.firebaseGetUser(created_by);
        this.creator.id = created_by;
      }
      this.currentUser = await this.firebaseCurrentUser();
      if (this.currentUser) {
        const { uid } = this.currentUser;
        this.isJoined = uid === created_by ? true : assistants ? assistants.includes(uid) : false;
      } else {
        this.isJoined = false;
      }
    },

    async onJoin(id, participantsList = null) {
      const currentUser = await this.firebaseCurrentUser();
      const { uid } = currentUser;
      if (uid) {
        if (participantsList) {
          participantsList.push(uid);
        } else {
          participantsList = [uid];
        }
        const assistants = participantsList;
        await this.updateEvents(id, { assistants });
        this.isJoined = true;
      } else {
        this.notificateLoginRequired();
      }
    },

    async onDisJoin(id, participantsList) {
      const currentUser = await this.firebaseCurrentUser();
      const { uid } = currentUser;
      if (uid) {
        const deletedInd = participantsList.findIndex(assistant => assistant.id === uid);
        participantsList.splice(deletedInd, 1);
        const assistants = participantsList;
        await this.updateEvents(id, { assistants });
        this.isJoined = false;
      } else {
        this.notificateLoginRequired();
      }
    }
  },
  mounted() {
    this.getCreatorData();
  }
};
</script>

<style scoped>
.cardGrid {
  margin-bottom: 1rem;
}

.cardLabel {
  width: 60%;
  background: #ffd000;
  border-radius: 0 10rem;
}

.joinBtn {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.joinBtn > button {
  width: 95%;
  background: linear-gradient(1deg, #ad571e, #ffd100);
}

.detailCard {
  text-align: start;
}

.footerCard {
  display: flex;
  justify-content: space-between;
  width: 50%;
  bottom: 0;
  position: absolute;
  align-items: flex-end;
}
</style>

<style>
.footerCard > .creator {
  position: relative !important;
}

#cardG > div > .vs-card {
  background: linear-gradient(232deg, #dc3545, #6b151f);
  max-width: none;
  box-shadow: 5px 5px 20px 0px rgb(0 0 0 / 60%);
  color: var(--main-byp-2);
}

#cardG.light > div > .vs-card {
  background: linear-gradient(232deg, #ffffff, #c3a6a6);
  max-width: none;
  box-shadow: 5px 5px 20px 0px #35010673;
  color: #212529;
}

#cardG > div > .vs-card > .vs-card__img {
  max-width: 40%;
  max-height: 155px;
}

#cardG > div > div > div .vs-card__interactions {
  width: 100%;
  padding: 0;
  background: #fffaf026;
  height: 100%;
  filter: drop-shadow(2px 4px 10px white);
  bottom: unset;
  left: unset;
  display: block;
}
</style>
