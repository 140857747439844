<template>
  <div class="creator">
    <vs-tooltip shadow interactivity>
      <vs-avatar :size="mini ? '30' : '50'" class="creator-avatar">
        <img loading="lazy" :src="creator.profileImg || profileImg" alt="avatar" />
      </vs-avatar>
      <template #tooltip>
        <div class="content-tooltip">
          <div class="body">
            <div class="text">
              Planes BYP
              <span>
                89
              </span>
            </div>
            <vs-avatar circle size="80" @click.stop.prevent="goProfile">
              <img loading="lazy" :src="creator.profileImg || profileImg" alt="Avatar" />
            </vs-avatar>
            <div class="text">
              Followers
              <span>
                {{ followersNum }}
              </span>
            </div>
          </div>
          <footer v-if="isExternal">
            <vs-button @click.prevent.stop="goProfile" warn circle icon border>
              <i class="fas fa-eye"></i>
            </vs-button>
            <vs-button success circle @click.prevent.stop="onOpenChat">
              Message
            </vs-button>
            <vs-button
              @click.prevent.stop="onFollow"
              color="#dc3545"
              circle
              icon
              border
              v-if="!isFollowed"
            >
              <i class="fas fa-heart"></i>
              <template #animate>
                <i class="fas fa-heart"></i>
              </template>
            </vs-button>
            <vs-button
              @click.prevent.stop="onUnfollow"
              v-if="isFollowed"
              icon
              relief
              color="#dc3545"
              animation-type="rotate"
            >
              <i class="fas fa-heart-broken"></i>
              <template #animate>
                <i class="fas fa-heart-broken"></i>
              </template>
            </vs-button>
          </footer>
        </div>
      </template>
    </vs-tooltip>
  </div>
</template>

<script>
import { followMix, chatMix, authMix } from "@/common/mixin.js";

export default {
  name: "Creator",
  mixins: [followMix, chatMix, authMix],
  props: ["creator", "mini", "currentUser"],
  data: () => {
    return {
      profileImg: "https://picsum.photos/400/400/?image=20",
      isFollowed: false,
      isExternal: false,
      followersNum: 0
    };
  },
  methods: {
    async initCreator() {
      const { creator } = this.$props;
      const currentUser = await this.firebaseCurrentUser();
      this.isExternal = creator && currentUser ? creator.id !== currentUser.uid : false;
    },

    goProfile(event) {
      event.stopPropagation();
      document.querySelectorAll(".vs-tooltip")[0].remove();
      if (this.$props.creator) {
        this.$router.push({
          name: "Profile",
          params: { creator: this.$props.creator }
        });
      }
    },

    async onUnfollow() {
      const follower = localStorage.getItem("user"),
        followed = this.$props.creator;
      try {
        await this.unfollow(followed, follower).then(response => {
          this.isFollowed = false;
          this.followersNum = this.followersNum - 1;
        });
      } catch (error) {
        console.error(error);
      }
    },

    async onFollow() {
      const follower = localStorage.getItem("user"),
        followed = this.$props.creator;
      try {
        await this.follow(followed, follower).then(response => {
          this.isFollowed = true;
          this.followersNum = this.followersNum + 1;
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getFollowing(followed_Id, follower) {
      try {
        await this.followers(followed_Id).then(response => {
          this.isFollowed =
            response.data.length > 0
              ? response.data
                  .map(followerUser => {
                    if (followerUser.follower_Id === follower) {
                      return true;
                    }
                  })
                  .includes(true)
              : false;
          this.followersNum = response.data.length;
        });
      } catch (error) {
        console.error(error);
      }
    },

    async onOpenChat() {
      const { id, username, profileImg } = this.$store.state.currentUser;
      const { creator } = this.$props;
      const chat = await this.getChat("single", [id, creator.id]);
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id: [id, creator.id],
          participants: [
            { id, username, profileImg: profileImg || null },
            { id: creator.id, username: creator.username, profileImg: creator.profileImg || null }
          ],
          messages: [],
          type: "single"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.openChatMix(data);
      } else {
        this.openChatMix(chat);
      }
    }
  },
  mounted() {
    this.initCreator();
    // this.isFollowed = this.isExternal && creator ? this.getFollowing(creator, username) : false;
  }
};
</script>

<style scoped>
.creator {
  width: min-content;
  position: absolute;
  bottom: 0.5rem;
}

.vs-avatar {
  cursor: pointer;
}

.content-tooltip .body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.content-tooltip .body .vs-avatar-content {
  margin-top: -30px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.content-tooltip .body .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.55rem;
  padding: 10px;
  font-weight: normal;
}
.content-tooltip .body .text span {
  font-weight: bold;
  font-size: 0.7rem;
}
.content-tooltip footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-tooltip h4 {
  padding: 8px;
  margin: 0px;
  text-align: left;
}
.content-tooltip p {
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 1rem;
  padding-bottom: 5px;
  padding-left: 8px;
}
</style>
