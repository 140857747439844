<template>
  <div>
    <b-card-group v-if="type === 2" class="card-group2">
      <MiniCard2
        class="CardsContainer2"
        @onRedirectPage="onRedirectPage"
        :description="card.description"
        :title="card.title"
        :img="card.image"
        :icon="card.icon"
        :id="card.id"
        :city="card.city"
        :createdBy="card.created_by"
        :hashtags="card.hashtags"
        :location="card.location"
        :modifiedBy="card.modified_by"
        :price="card.price"
        :private="card.private"
        :created_at="card.created_at"
        :updated_at="card.updated_at"
        :participant="card.participant"
        :startDate="new Date(card.startDate)"
        :endDate="new Date(card.endDate)"
        :aforo="card.aforo"
        :inviteKey="card.inviteKey"
        v-for="card in cards"
        v-bind:key="card.id"
      />
    </b-card-group>
    <b-card-group v-else class="CardsContainer">
      <!-- <MiniCard
        @onRedirectPage="onRedirectPage"
        :card="card"
        v-for="card in cards"
        v-bind:key="card.id"
      ></MiniCard> -->
      <Card2 :card="card" v-for="card in cards" v-bind:key="card.id" />
    </b-card-group>
  </div>
</template>

<script>
// import MiniCard from "@/components/CARDS/MiniCard.vue";
import MiniCard2 from "@/components/CARDS/Minicard2.vue";
// import Card from "@/components/CARDS/Card.vue";
import Card2 from "@/components/CARDS/Card2.vue";

export default {
  name: "cardGrid",
  props: ["cards", "type"],
  data() {
    return {};
  },
  components: {
    // MiniCard,
    MiniCard2,
    // Card,
    Card2
  },
  methods: {
    onRedirectPage(id) {
      this.$emit("onRedirectPage", id);
    }
  }
};
</script>

<style scoped>
/* .CardsContainer {
  margin: 5% 1% 5% 0rem;
  padding-left: 1rem;
} */

/* .card-group {
  min-width: 70%;
  width: 70%;
} */

.card-group2 {
  width: 100%;
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
  .CardsContainer {
    margin: 0;
  }
  .card-group {
    width: 100%;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-around;
    align-items: stretch;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
