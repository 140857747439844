<template>
  <vs-avatar-group max="5">
    <vs-avatar v-for="assistant in assistants" :key="assistant.id">
      <img loading="lazy" :src="assistant.ProfileImg" :alt="assistant.nick" />
    </vs-avatar>
  </vs-avatar-group>
</template>

<script>
export default {
  name: "ParticipantsAvatar",
  props: ["img", "nick", "id", "assistants"],
  data() {
    return {
      img2:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQuJCEfooUBWITHKYc7CrcrIs61S6OP_BFiFkWmCwWrx3aiV9v1&usqp=CAU"
    };
  },
  methods: {
    onRedirectPage() {
      this.$emit("onRedirectPage", this.$el.children[0].id);
    },
    async onJoin() {
      const body = {
        username: this.createdBy,
        event_id: this.id
      };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .post("/participation", body)
        .then(response => {
          this.joined = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
