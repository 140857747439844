<template>
  <div>
    <br />
    <vs-button id="loginBtn" @click="goLoginPage" color="rgb(59,222,200)" gradient icon block>
      <i class="fas fa-user-plus"></i> Login
      <template #animate>
        <i class="fas fa-user-plus"></i>
      </template>
    </vs-button>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "LoginBtn",
  methods: {
    goLoginPage() {
      router.push("/Login");
    }
  },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
